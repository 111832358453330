import { Capacitor } from '@capacitor/core';

import {
    UserEmail,
    UserToken,
    EthiClaims,
    TreeStore,
    RootStore,
    DataUploaded,
    Path,
    Data,
    Version,
    DatesUploaded,
    GraphsCached,
    GraphsSeen,
    StripeInfo,
    MetaData,
    UserInfo,
    Tracking,
    Promises,
    LiveConnections,
    DemoMode,
    IsDev,
    IsElectron,
    ShowDetailsFor,
    Platform
} from '$lib/store.js';

import {
    currentTime,
    DAY_SECS,
    decodeJWT,
    get_sugar,
    delete_sugar,
    getJWT,
    getRefresh,
    serverURL,
    post_sugar,
    sleep,
    refresh,
    objToQueryString,
    setJWT,
    setRefresh,
    getAllMetadata,
    getDataUploaded,
    getCachedGraphs,
    getUserInfo,
    checkLocal
} from '$lib/utils.js';
import { get } from 'svelte/store'
import { initializeApp } from "firebase/app";
import { getAuth as initAuth, AuthCredential, signInWithRedirect, initializeAuth, signInWithCredential, signInWithPopup, GoogleAuthProvider, indexedDBLocalPersistence, signOut, sendPasswordResetEmail, verifyPasswordResetCode as fbverifyPasswordResetCode, confirmPasswordReset as fbconfirmPasswordReset, checkActionCode, applyActionCode, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, sendEmailVerification as userSendEmailVerification } from "firebase/auth";
import { goto } from '$app/navigation';
import { page } from '$app/stores';
import dayjs from 'dayjs'
import { getFirestore, doc, persistentLocalCache, getDoc, getDocs, writeBatch, collection, deleteDoc, disableNetwork, enableNetwork, getDocFromCache, setDoc } from "firebase/firestore";


let omnipilotConfig = {
    apiKey: "AIzaSyC1AfHTx9MiEyakLmQrAWG0KL1EViO9eMY",
    authDomain: "autopilot-43e27.firebaseapp.com",
    projectId: "autopilot-43e27",
    storageBucket: "autopilot-43e27.appspot.com",
    messagingSenderId: "565563702006",
    appId: "1:565563702006:web:82b020240762b5148b0ad7",
    measurementId: "G-YRQPCM2BWV"
};

let auth, app, omnipilot;
async function init() {
    if (typeof window !== 'undefined') {
        omnipilot = window.location.href.includes('omnipilot') || (await window.api?.version)?.app == 'omnipilot' || get(Version)?.app == "omnipilot" || (new URL(window.location.href)).searchParams.app == "omnipilot"
        console.log({ omnipilot })
        var appConfig = omnipilot ? omnipilotConfig : process.env.FIREBASE_API;
        // Initialize Firebase

        app = initializeApp(appConfig);
        window.firebaseApp = app
        if (Capacitor.isNativePlatform()) {
            auth = initializeAuth(app, {
                persistence: indexedDBLocalPersistence,
                localCache: persistentLocalCache(/*settings*/{})
            })
        } else {
            auth = initAuth(app, {
                persistence: indexedDBLocalPersistence,
                localCache: persistentLocalCache(/*settings*/{})
            });
            window.firebaseAuth = auth;
        }
    } else {
        console.log("init must be called by user browser, not JS server.")
        return false
    }
}
let initPromise = init()


export async function getLoginToken(email, password) {
    if (!(typeof window !== 'undefined')) throw ("getLoginToken must be called by user browser, not JS server.");

    // When the user signs in with email and password.
    const user = await signInWithEmailAndPassword(auth, email, password).catch(e => {
        console.error({ e }, "getLoginTokenfbAuthsignInwEmail");
        if (e?.message !== "Invalid authority in continue url") throw (e);
    });
    if (!user) throw ("Wrong email or password.");
    console.log({ user })
    const token = await auth.currentUser.getIdToken().catch(e => {
        console.error({ e }, "getLoginTokenfbAuthcurrentUser");
        if (e?.message !== "Invalid authority in continue url") throw (e); throw (e);
    });
    if (!token) throw ("Invalid user, couldn't fetch IdToken.");
    return [token, user];
}

export async function login(options, referral) {
    if (!(typeof window !== 'undefined')) throw ("login must be called by user browser, not JS server.");
    let token, user, error;
    console.log("logging in with options", options)
    async function googleLogin(auth, provider) {

        await signInWithPopup(auth, provider)
            .then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                console.log({ credential })
                token = await auth.currentUser.getIdToken().catch(e => {
                    console.error({ e }, "getLoginTokenfbAuthcurrentUser");
                    if (e?.message !== "Invalid authority in continue url") throw (e); throw (e);
                });
                // The signed-in user info.
                user = result.user;
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData?.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                console.error({ errorCode, errorMessage, email, credential }, "googleLogin");
                error = true;
                // ...
            });
    }
    if (options.provider === "google") {
        const provider = new GoogleAuthProvider();
        // provider.addScope('profile');
        // provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        await googleLogin(auth, provider).catch(e => { console.error(e); error = true });
        if (omnipilot || get(Version).app == 'omnipilot') {
            let created = await createUserAccount(token, referral, true, "be1577ta");
            console.log({ created })
            if (created?.ok) {
                options.created = true;
            }
        }
        if (error) return;
    } else {
        let { email, password } = options;
        [token, user] = await getLoginToken(email, password);
        user = user.user
    }
    console.log({ token, user });
    if (!token) throw ("Invalid user, couldn't fetch IdToken.");
    if (!user) throw ("Invalid user, couldn't fetch user.");
    let redirect = options.redirect || "";
    let userToken = {
        email: user.email,
        emailVerified: user.emailVerified,
        metadata: user.metadata,
        token: token,
        uid: user.uid,
        firebaseData: { user }
    }

    localStorage.setItem("userToken", JSON.stringify(userToken))
        ;
    let data = { 'idToken': token };
    let loginSuccess;
    // Get the httpOnly Cookie by posting idToken to flask-server
    let redirectURL;
    let server_response
    if (!omnipilot && get(Version).app != "omnipilot") {
        server_response = await post_sugar(`${serverURL}/api/v1/user/login/`, data)
            .then(async function (json_data) {
                if (!json_data || !json_data.access_token) throw "Login failed, " + JSON.stringify(json_data)
                loginSuccess = true;
                setJWT(json_data.access_token);
                let user = decodeJWT(json_data.access_token, "auth192");
                if (user) {
                    EthiClaims.set(user.user_claims || user);
                }
                setRefresh(json_data.refresh_token)
                if (redirect) redirectURL = `${decodeURIComponent(redirect).split('').map(char => String.fromCharCode(char.charCodeAt(0) + 6)).join("")}/login?access_token=${json_data.access_token}&refresh_token=${json_data.refresh_token}${options.created ? '&created=true' : ""}&user_token=${encodeURIComponent(localStorage.userToken)}`
                return user;
            })
            .catch(async e => {
                console.error(options.provider + "ServerLoginFailed", e)
                let created = await createUserAccount(token, referral, true);
                console.log(created)
                if (created?.ok) {
                    options.created = true;
                    return await post_sugar(`${serverURL}/api/v1/user/login/`, data)
                        .then(async function (json_data) {
                            if (!json_data || !json_data.access_token) throw "Login failed, " + JSON.stringify(json_data)
                            loginSuccess = true;
                            setJWT(json_data.access_token);
                            let user = decodeJWT(json_data.access_token, "auth211");
                            if (user) {
                                EthiClaims.set(user.user_claims || user);
                            }
                            setRefresh(json_data.refresh_token)
                            if (redirect) redirectURL = `${decodeURIComponent(redirect).split('').map(char => String.fromCharCode(char.charCodeAt(0) + 6)).join("")}/login?access_token=${json_data.access_token}&refresh_token=${json_data.refresh_token}${options.created ? '&created=true' : ""}&user_token=${encodeURIComponent(localStorage.userToken)}`
                            return user;
                        }).catch(e => console.error("normalServerLoginFailed", e))
                }
            });
    } else {
        if (token) loginSuccess = true;
        EthiClaims.set(user)
        server_response = user
        if (redirect) redirectURL = `${decodeURIComponent(redirect).split('').map(char => String.fromCharCode(char.charCodeAt(0) + 6)).join("")}/login?user_token=${encodeURIComponent(localStorage.userToken)}`
    }
    UserEmail.set(user?.email || get(EthiClaims)?.email || get(UserToken)?.email || "")
    if (userToken)
        UserToken.set(userToken)
    let userInfo;
    if (loginSuccess) {
        await getUserInfo("login");
        userInfo = get(UserInfo);
        if (!omnipilot || get(Version).app != "omnipilot") {
            getStripeInfo();
            getAllMetadata();
            getDataUploaded();
            getCachedGraphs();
        }
        if (!get(IsDev) && window.firebaseAuth?.currentUser.uid) {
            window.analytics?.track?.('Signed In', {
                platform: get(Platform),
                environment: get(IsDev) ? 'dev' : 'production',
                email: get(UserEmail)
            });
            window.analytics?.identify(window.firebaseAuth?.currentUser.uid, {
                email: get(UserEmail),
                platform: get(Platform),
                environment: get(IsDev) ? 'dev' : 'production'
            });
        }
        if (options.noRedirect) return server_response
        if (redirect) {
            console.log(redirect)
            console.log("redirecting to " + redirectURL)
            // redirectURL = `${decodeURIComponent(redirect).split('').map(char => String.fromCharCode(char.charCodeAt(0) + 6)).join("")}/login?access_token=${json_data.access_token}&refresh_token=${json_data.refresh_token}${options.created ? '&created=true' : ""}&user_token=${encodeURIComponent(localStorage.userToken)}`
            let newURL = '/done' + (location != undefined && location || get(page)?.url).search
            goto(redirectURL);
            if (get(IsElectron)) {
                console.log("going to " + newURL + " in 4 seconds")
                setTimeout(() => {
                    goto(newURL)
                }, 4000)
            }
            return server_response;
        }
        else if (userInfo && !userInfo.onboardingComplete && !get(Path).includes("onboard") || get(Version).app == "omnipilot" && !get(UserInfo).onboardingComplete || !get(UserInfo).syncActivityWatchEnabled) {
            if (!get(IsElectron))
                goto('/onboard')
            else
                goto('/onboard')
        }
        else window.location.href.includes('/ai') ? window.location.reload() : goto("/onboard/complete");
    } else {
        throw ("Error logging in, contact hello@" + (omnipilot || get(Version).app === "omnipilot" ? "omnipilot.ai" : "magicflow.com"));
        return server_response;
    }

    return server_response;
}

export async function checkEmailVerified() {
    if (!(typeof window !== 'undefined'))
        throw "checkEmailVerified must be called by user browser, not JS server.";
    if (!get(UserEmail)) return;
    let claims = get(EthiClaims);
    if (claims.email_verified) return;
    let newToken = await refresh();
    if (!(newToken || getJWT())) return;
    let user = await decodeJWT(newToken || getJWT(), "auth290");
    if (user) {
        EthiClaims.set(user.user_claims || user);
        let userToken = get(UserToken)
        userToken.emailVerified = user.email_verified || (user.user_claims && user.user_claims.email_verified)
        if (userToken)
            UserToken.set(userToken)
    }
    else console.log("checkEmailVerified didn't refresh user properly")
}
typeof window !== "undefined" ? window.decodeJWT = decodeJWT : null;

export async function updateEmail(new_email) {
    if (!(typeof window !== 'undefined')) throw ("updateEmail must be called by user browser, not JS server.");

    let user = auth.currentUser;
    if (!user) {
        // reauthenticate
        throw ("Need to reauthenticate to update email. Redirecting...");
    }
    let response = await user.updateEmail(new_email).catch(e => {
        throw (e);
    });
    return response;
}

export async function changeName(new_name) {
    if (!(typeof window !== 'undefined')) throw ("changeName must be called by user browser, not JS server.");


    let response = await post_sugar(`${serverURL}/api/v1/user/change_name/`, { 'new_name': new_name }).catch(e => {
        throw (e);
    });
    await refresh()
    return response;
}

export async function updatePassword(old_password, new_password) {
    if (!(typeof window !== 'undefined')) throw ("updatePassword must be called by user browser, not js server.");

    let email = get(UserEmail)
    const user_credential = await signInWithEmailAndPassword(auth, email, old_password).catch(e => {
        console.error({ e },);
        throw (e);
    });
    if (!user_credential) throw ("wrong password.");
    return await user_credential.user.updatePassword(new_password).catch(e => {
        throw (e)
    });
}

export async function deleteUser(email, password, provider) {
    if (!(typeof window !== 'undefined')) throw ("deleteUser must be called by user browser, not js server.");
    let error;
    let user = get(UserToken)
    await login(provider !== "google.com" ? { email, password } : { provider: "google" }).catch(e => error = e);
    if (error) throw (error);
    let firebase_response = "waiting";

    let removeListener = await onAuthStateChanged(auth, async function (user) {
        console.log("probs deleting user ", user, "if ", (user && user?.email === email));
        if (user && user?.email === email) {

            firebase_response = await user.delete().catch(e => {
                throw (e);
            });

            //goto('/logout')
        } else {
        }
    }, undefined, (u) => u());

    while (firebase_response == "waiting") {
        await sleep(2000);;
    }
    console.log({ removeListener })
    removeListener && removeListener();
    if (!get(IsDev))
        window.analytics?.track?.('Account Deleted', {
            platform: get(Platform),
            environment: get(IsDev) ? 'dev' : 'production',
            email: email
        });
    await logout(true);
    return firebase_response;
}

export async function signup(email, password, betaCode, referral, marketing) {
    if (!(typeof window !== 'undefined')) throw ("signup must be called by user browser, not JS server.");

    // Signup
    // Build data object to send to flask-server

    var response = { "res": false };

    // Signup
    let user_credential = await createUserWithEmailAndPassword(auth, email, password).catch(e => {
        console.error({ e }, "createUserWithEmailAndPassword");
        if (e?.message !== "Invalid authority in continue url") throw (e);
    });
    // await user_credential.user.updateProfile({
    //     displayName: username
    // })

    // Get the ID Token
    let id_token = await auth.currentUser.getIdToken().catch(e => {
        console.error({ e }, "currentUser.getIdToken()");
        if (e?.message !== "Invalid authority in continue url") throw (e);
    });
    console.log({ user_credential, id_token })
    if (omnipilot || get(Version).app == "omnipilot" && id_token) {
        return {
            ok: true,
            id_token,
            user: user_credential.user,
            user_credential
        }
    }
    // Create headers from token
    response = createUserAccount(id_token, referral, marketing, betaCode)
    console.log({ response })

    // sendEmailVerification();
    if (response?.ok && !get(IsDev)) window.analytics?.track?.('Account Created', {
        platform: get(Platform),
        environment: get(IsDev) ? 'dev' : 'production',
        email: email
    });

    return response;
}
async function createUserAccount(id_token, referral, marketing, betaCode) {
    const headers = {
        "Authorization": `Bearer ${id_token}`,
        "Code": betaCode || 'be1577ta'
    };

    let params = {};
    if (referral) params.ref = referral;
    if (marketing !== undefined) params.marketing = marketing;
    let queryString = objToQueryString(params);
    let signupUrl = `${serverURL}/api/v1/user/create`;
    if (queryString) {
        signupUrl = `${signupUrl}?${queryString}`
    }


    // Call create to set encryption key
    let response = await fetch(signupUrl, {
        method: 'POST',
        credentials: 'include',
        headers: headers
    }).catch(r => console.error({ signupError: r }));
    return response
}
export async function sendEmailVerification(path) {
    var actionCodeSettings = {
        url: `https://${get(IsDev) ? "hedge.ethi.me" : "app." + (omnipilot || get(Version).app === "omnipilot" ? "omnipilot.ai" : "magicflow.com")}/${path || ""}`,
    };
    await onAuthStateChanged(auth, function (user) {
        if (user) {
            userSendEmailVerification(auth.currentUser, actionCodeSettings).catch(e => {
                console.error({ e }, "sendEmailVerification")
            });// User is signed in.
        } else {
            // No user is signed in.
        }
    })
    return;
}

export function clearStores(stayLoggedIn, keepData) {
    // Remove local storage of user info
    let token = getJWT();
    let refresh = getRefresh();
    let userToken = localStorage.userToken
    if (!stayLoggedIn) {
        UserEmail.set("");
        UserToken.set("");
        UserInfo.set({});
        EthiClaims.set({});
        StripeInfo.set(undefined);
        Tracking.set({
            watcherStatus: "running", showTimeline: false, caffeine: { tracking: false },
            food: { tracking: false },
            other: { tracking: false },
            mood: {
                tracking: false,
                ethiCache: [],
                latest: undefined
            }
        })
        Promises.set({})
        LiveConnections.set({})
        DemoMode.set(false)
        Data.set({})
        ShowDetailsFor.set({ '/': '' })
    }
    clearLocalstorage();
    if (stayLoggedIn) {
        setJWT(token);
        setRefresh(refresh);
        localStorage.userToken = userToken
    }
    TreeStore.set(undefined);
    RootStore.set(undefined);
    GraphsCached.set([]);
    GraphsSeen.set([]);
    if (!keepData) {
        DatesUploaded.set({})
        DataUploaded.set({});
        MetaData.set({});
    }
    setTimeout(() => localStorage.clear(), 1000)
}

export function clearLocalstorage() {
    let theme = localStorage.getItem('theme');
    localStorage.clear();
    localStorage.setItem('theme', theme);
}

export async function logout(isDeleted) {
    console.log("Logging out...")
    if (!(typeof window !== 'undefined')) {
        console.error("logout must be called by user browser, not JS server.");
        return
    };
    if (!get(IsDev))
        await window.analytics?.track?.('Signed Out', {
            platform: get(Platform),
            environment: get(IsDev) ? 'dev' : 'production',
            email: get(UserEmail)
        });
    window.analytics?.reset()
    let response;
    let response2;
    let response3;
    if (!isDeleted) {
        response = delete_sugar(`${serverURL}/api/v1/user/logout/`)
        response2 = fetch(`${serverURL}/api/v1/user/logout_refresh/`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                "Authorization": `Bearer ${getRefresh()}`
            }
        }).catch(function (error) {
            console.error({ error }, "logoutdeleterequest")
        });
        console.log("firebaselogout")
        response3 = new Promise((resolve, reject) => {

            signOut(auth).then(function () {
                // Sign-out successful.
                console.log("logged out of firebase")
                resolve(true);
            }).catch(function (error) {
                // An error happened.
                console.error({ error }, "logoutfirebasesignout")
            });
        })
    }
    clearStores();

    goto(omnipilot ? "/onboard/demo" : "/login")
    await response;
    await response2;
    await response3;
    console.log("logged out")

    goto(omnipilot ? "/onboard/demo" : "/login")
    return
}
if (typeof window !== 'undefined') {
    window.signInWithCredential = signInWithCredential
    window.GoogleAuthProvider = GoogleAuthProvider
}
export async function getAuth() {

    // Check whether user is logged in with valid Cookie
    // If yes, update localStorage & return claims
    // If no,  remove localStorage & throw error
    if (typeof window === 'undefined') {
        console.error("getAuth must be called by user browser, not JS server.");
        return false
    }

    await initPromise
    omnipilot = window.location.href.includes('omnipilot') || get(Version)?.app == "omnipilot" || (await window.api?.version)?.app == 'omnipilot' || (new URL(window.location.href)).searchParams.app == "omnipilot"
    if (omnipilot || get(Version).app == "omnipilot") {
        if (!localStorage.userToken) return false
        let token = checkLocal('userToken') || get(UserToken)
        if (token) {
            UserToken.set(token)
            EthiClaims.set(token)
        }
        await getStripeInfo();
        console.log({ token })
        if (token?.email) UserEmail.set(token.email)

        // console.log("asdf", auth.authCredential, AuthCredential)
        // signInWithCredential(auth, token.firebaseData.user).catch(e => console.error(e))
        // auth.currentUser = token.firebaseData.user

        return token
    }
    let token = getJWT();
    if (!token || token == "undefined") return false;
    let user = await decodeJWT(token, "auth597");
    if (!user) return false
    if (localStorage.userToken) try {
        UserToken.set(checkLocal("userToken"))
    } catch (e) {
        console.error({ e }, "getAuth")
    }
    let claims = (user?.user_claims || user)
    if (!(claims?.encryption_key)) {
        await refresh();
        token = getJWT();
        user = await decodeJWT(token, "auth607");
        claims = (user?.user_claims || user)
        if (!(claims?.encryption_key)) {

        }
        UserEmail.set((claims || {}).email)
        if (user) {
            EthiClaims.set(claims);
            window.analytics?.identify(claims?.identity || claims?.uid || get(UserToken).uid || get(UserToken).identity, {
                email: get(UserEmail),
                platform: get(Platform),
                environment: get(IsDev) ? 'dev' : 'production'
            });
        }
        const time = currentTime();
        const expiry = user?.exp || (await refresh())?.exp;
        if (!expiry && !window.location.href.includes('omnipilot')) {
            console.log(user, user?.exp)
            goto("/logout");
            return
        }
        if (expiry && expiry > time) {
            console.log(
                `${expiry} > ${time} by ${-(time - expiry) / DAY_SECS
                } days, valid JWT token.`
            );
            return user;
        } else {
            console.log("JWT token expired, refreshing...")
            return await refresh().then(function () {
                return decodeJWT(getJWT(), "auth636");
            }).catch(function (error) {
                console.log("failed to refresh expired JWT token", decodeJWT(getRefresh(), "auth638"))
                console.error(error)
                return false;
            })
        }
        return user;
    }
}
// export async function resetEncryption() {
//     
//     let user;
//     if (confirm("Resetting your encryption key means any uploaded data will no longer be usable. You will need to upload new data.")) {

//         let serverResponse = await get_sugar(`${serverURL}/api/v1/user/reset_encryption_key`)
//             .catch(function (error) {

//             })
//             .then(async (response) => {

//                 return await res.json()
//             })
//             .then((res) => {
;
//                 setJWT(res.access_token)
//             })
//             .then(async function () {
//                 await deleteAllData()
//             })
//         user = await getAuth()
//         alert("Encryption key reset.")
//         return user
//     }
// }

export async function resetPassword(email) {
    var emailAddress = email;

    sendPasswordResetEmail(auth, emailAddress).then(function () {
    }).catch(function (error) {
        console.error({ error })
    });
}

export async function verifyEmail(actionCode) {
    let resp = await applyActionCode(auth, actionCode).catch((e) => {
        throw (e);
    });
    return resp;
}

export async function recoverEmail(actionCode) {

    let resp = await checkActionCode(auth, actionCode).catch((e) => {
        throw (e);
    });
    let email = resp['data']['email'];
    await applyActionCode(auth, actionCode).catch((e) => {
        throw (e);
    });
    return email;
}

export async function verifyPasswordResetCode(actionCode) {

    // Returns email address of user if valid
    // Verify the password reset code is valid.
    return await fbverifyPasswordResetCode(auth, actionCode)
        // TODO: Show the reset screen with the user's email and ask the user for
        // the new password.
        .catch(function (error) {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
            console.error({ error });
            throw ("Error verifying password reset code. Request new reset password link.");
        });
}

export async function confirmPasswordReset(actionCode, newPassword) {
    // Save the new password.
    let email = await verifyPasswordResetCode(actionCode);
    console.log(email)
    let resp = await fbconfirmPasswordReset(auth, actionCode, newPassword)
        .catch(function (error) {
            console.error(error);
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
        });

    // Password reset has been confirmed and new password updated.
    return email;
}

export async function getStripeInfo() {
    let v = await window.api?.version
    let app = (get(Version))?.app || v?.app || (new URL(window.location.href)).searchParams.app
    if (app === "omnipilot") {
        let result = await fetch('https://stripe.magicflow.workers.dev/?email=' + (get(UserEmail) || checkLocal('userToken')?.email)).then(r => r.text()).catch(e => console.error(e))
        let hasPaid = result === "Customer has an active subscription"

        let stripeInfo = {
            lastUpdated: dayjs().format(),
            omnipilot: true,
            subscriptions: {
                data: hasPaid ? [
                    {
                        active: true,
                    }
                ] : [],
                total_count: hasPaid ? 1 : 0
            }
        }
        StripeInfo.set(stripeInfo || {});
        return stripeInfo;
    }
    let infoURL = `/api/v1/oauth/stripe/info/`;
    let stripeInfo = await get_sugar(infoURL)
    if (stripeInfo)
        stripeInfo.lastUpdated = dayjs().format()
    StripeInfo.set(stripeInfo || {});
    return stripeInfo;
}

let db;
async function initDB() {
    let i = await initPromise;
    if (i == false) return
    console.log("initDB")
    await refresh();
    if (await getAuth() == false) return
    if (!db) {
        db = getFirestore(app);

    }
}
export async function readFirebaseDoc(reference) {
    if (typeof window == "undefined" || !window.firebaseAuth?.currentUser?.uid) {
        console.error("No claims found. Please log in.")
        return
    }
    if (!reference.collection || !reference.docPath) {
        console.error("No collection/document given.")
        return
    }
    await initDB();
    await enableNetwork(db);


    window.firebaseAuth?.currentUser

    const docRef = doc(db, reference.collection, window.firebaseAuth?.currentUser?.uid, ...(reference.docPath || []))
    let docSnap
    try {
        docSnap = await getDoc(docRef);
    }
    catch (e) {
        console.error(e)
        docSnap = await getDocFromCache(docRef)
    }

    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        return { id: docSnap.id, data: docSnap.data(), lastUpdated: docSnap._document.version.timestamp.seconds * 1000 }
    } else {
        // doc.data() will be undefined in this case
        console.error("No such document!");
        return false
    }
}
export async function readAllFirebaseDocs(reference) {
    // let's time this function
    let start = new Date().getTime();
    console.log("readAllFirebaseDocs")
    await initDB();
    console.log("readAllFirebaseDocs1")
    let claims = get(EthiClaims);
    if (typeof window == "undefined" || !window.firebaseAuth?.currentUser?.uid) {
        console.error("No claims found. Please log in.")
        return
    }
    if (!reference.docPath) {
        console.error("No collection given.")
        return
    }
    if (reference.offline) {
        // set fireStore db to offline
        await disableNetwork(db);
        console.log("Network disabled!");
    }

    let querySnapshot = getDocs(collection(db, ...(reference.docPath || [])))
    console.log(querySnapshot, "readAllFirebase")
    querySnapshot = await querySnapshot
    let documents = []
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc)
        documents.push({ id: doc.id, data: doc.data(), lastUpdated: doc._document.version.timestamp.seconds * 1000 })
    });
    if (reference.offline) {
        // set fireStore db to offline
        await enableNetwork(db);
        console.log("Network ensabled!");
    }
    console.log("readAllFirebaseDocs took " + (new Date().getTime() - start) + "ms")
    return documents;
}


export async function writeFirebaseDoc(reference, data) {
    await refresh();
    await initDB();
    let claims = get(EthiClaims);
    if (typeof window == "undefined" || !window.firebaseAuth?.currentUser?.uid) {
        console.error("No claims found. Please log in.")
        return
    }
    if (!reference.collection) {
        console.error("No collection given.")
        return
    }


    let path = [reference.collection, window.firebaseAuth?.currentUser?.uid, ...(reference.docPath || [])].join('/');
    console.log('writing doc to path', path, 'with data', data)
    const docRef = doc(db, path)
    let error = false;
    await setDoc(docRef, data).catch((e) => {
        console.error('writeFirebaseDoc', ({ reference, data }), e)
        error = e
    })
    let details = {
        ...reference,
        id: reference.docPath?.[reference.docPath?.length - 1] || 0,
        data,
        path,
        docRef
    }
    console.log("Document written with ID: ", details);

    return {
        id: reference.docPath?.[reference.docPath?.length - 1] || 0,
        data,
        error
    }
}

export async function deleteManyFirebaseDocs(reference, docsData) {
    await initDB();
    let claims = get(EthiClaims);
    if (typeof window == "undefined" || !window.firebaseAuth?.currentUser?.uid) {
        console.error("No claims found. Please log in.")
        return
    }
    if (!reference.collection) {
        console.error("No collection given.")
        return
    }
    if (!reference.docs) {
        console.error("No documents given.")
        return
    }

    const batch = writeBatch(db);

    // Delete the city 'LA'
    reference.docPaths.forEach((docPath) => {
        const docRef = doc(db, reference.collection, window.firebaseAuth?.currentUser?.uid, ...(docPath || []))
        batch.delete(docRef);
    })

    // Commit the batch
    let error;
    await batch.commit().catch(e => { error = e; console.error('deleteManyFirebaseDocs', e) });
    return {
        collection: reference.collection,
        docs: reference.docs,
        data: docsData,
        deleted: !error,
        error
    }
}
export async function deleteFirebaseDoc(reference, data) {
    await initDB();
    let claims = get(EthiClaims);
    if (typeof window == "undefined" || !window.firebaseAuth?.currentUser?.uid) {
        console.error("No claims found. Please log in.")
        return
    }
    if (!reference.collection) {
        console.error("No collection given.")
        return
    }
    if (!reference.docPath) {
        console.error("No document given.")
        return
    }




    const docRef = doc(db, reference.collection, window.firebaseAuth?.currentUser?.uid, ...(reference.docPath || []))
    await deleteDoc(docRef);
    return {
        collection: reference.collection,
        id: reference.docPath?.[reference.docPath?.length - 1] || 0,
        data,
        deleted: true
    }
}


typeof window !== "undefined" ? window.readFirebaseDoc = readFirebaseDoc : null;
typeof window !== "undefined" ? window.readAllFirebaseDocs = readAllFirebaseDocs : null;
typeof window !== "undefined" ? window.writeFirebaseDoc = writeFirebaseDoc : null;
typeof window !== "undefined" ? window.getStripeInfo = getStripeInfo : null;