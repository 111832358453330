import { writable, get } from 'svelte/store';
export const Version = writable({});
import { Capacitor } from '@capacitor/core';
import { checkLocal, serverURL } from '$lib/utils.js';
// import { serverURL } from './utils';
import dayjs from "dayjs";
export const Platform = writable(isElectron() ? (isApple() ? 'macos' : 'windows') : Capacitor.getPlatform())
export const IsNative = writable(Capacitor.isNativePlatform());
export const IsElectron = writable(isElectron());
function isElectron() {
    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return true;
    }
    if (typeof window !== 'undefined' && window.api) {
        return true;
    }

    // Main process
    if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return true;
    }

    // Detect the user agent when the `nodeIntegration` option is set to true
    if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
        return true;
    }
    // console.log({ window: typeof window !== 'undefined' && window?.process?.type, navigator: typeof navigator !== 'undefined' && navigator.userAgent, process: typeof process !== 'undefined' && process.versions })
    return false;
}
function isApple() {
    if (typeof window !== 'undefined') {
        var canvas = document.createElement('canvas');
        var gl;
        var debugInfo;
        var vendor;
        var renderer;

        try {
            gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
        } catch (e) {
        }

        if (gl) {
            debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
            vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
            renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
        }
        return renderer?.includes('Apple')
    }
}
let urls = {
    staging: "https://flask-server-rya4g2ukoq-ez.a.run.app",
    prod: "https://flask-server-ulyvmgpd2q-ez.a.run.app"
}
const isDev = () => typeof window !== 'undefined' && window.location.href.includes('localhost:') || typeof process !== 'undefined' && process.env.DEV_DATABASE === true || serverURL !== urls.prod
export const RenderDelayedContent = writable(false);

export const UserInfo = writable(isDev() ? {} : {});
export const NotPaid = writable(false);
export const UserEmail = writable(isDev() ? "" : "");
export const IsVisible = writable(false)
export const IsDev = writable(isDev());
export const UserToken = writable({});
export const EthiClaims = writable(isDev() ? {} : {});
export const TreeStore = writable({});
export const RootStore = writable({});
export const DataUploaded = writable({});
export const DatesUploaded = writable({});
export const GraphsCached = writable([]);
export const GraphsSeen = writable([]);
export const StripeInfo = writable();

export const MetaData = writable();
export const Karma = writable(1);
export const LiveConnections = writable({ twitter: {}, spotify: {}, apple: {}, whoop: {} });
export const DemoMode = writable(false);
export const DateRange = writable({
    end: dayjs().subtract(4, "hour").endOf("day"),
    start: dayjs().subtract(4, "hour")
        .subtract(7, "day")
        .endOf("day"),
    daysInPast: 0,
    daysInRange: Array(7)
        .fill()
        .map((p, i) => dayjs().subtract(4, "hour").subtract(6, "day").startOf("day").add(i, 'day').format('YYYY-MM-DD')),
    todayIn10Minutes: Array(24 * 6)
        .fill()
        .map((a, i) =>
            dayjs()
                .subtract(4, 'hour')
                .subtract(0, 'day')
                .startOf('day')
                .add(4, 'hour')
                .add(i * 10, 'minute')
        )
});

export const TodaysData = writable({})
export const Insights = writable({ correlations: [], trends: [] })
export const Data = writable({})
export const Leaderboards = writable([])
let newThread = {
    data: { title: 'New chat', messages: [] },
    id: Date.now().toString()
};
export const Threads = writable([{ ...newThread, data: { ...newThread.data }, id: Date.now().toString() }])

export const Promises = writable({})
export const CachedProductivityMetrics = writable({})
export const CurrentActivity = writable({})
export const Tracking = writable({
    watcherStatus: "loading", showTimeline: false, caffeine: { tracking: false },
    food: { tracking: false },
    other: { tracking: false },
    mood: {
        tracking: false,
        ethiCache: typeof window !== 'undefined' ? Object.values(
            checkLocal(`["timeseries","time_series/ethi","noCache"]`) || {}).flat() : [],
        latest: undefined
    }
})
export const Activities = writable([])
export const ShowDetailsFor = writable({ '/': !isElectron() ? '' : '' })
export const Path = writable(typeof window == "undefined" ? '/' : window.location.pathname)
export const Loading = writable(true)
export const Onboarding = writable(false)

// How the local, syncing data store would work

if (typeof window !== "undefined") {
    // (async () => {
    //     let v = await window.api?.version || {
    //         app: window.location.href.includes('omnipilot') ? 'omnipilot' :
    //             (new URL(window.location.href)).searchParams.app ||
    //             (new URL(window.location.href)).searchParams.get?.('app') || 'magicflow'
    //     };
    //     console.log({ v })
    //     if (v)
    //         Version.set(v)
    // })()
    window.getStore = (name) => get({ Version, ShowDetailsFor, Threads, CachedProductivityMetrics, MetaData, DataUploaded, DatesUploaded, GraphsCached, UserInfo, UserEmail, LiveConnections, Promises, Data, IsDev, TodaysData, StripeInfo, IsElectron, Path, Platform, Tracking, NotPaid, Leaderboards, DateRange, UserToken, EthiClaims }[name])
    window.isElectron = isElectron()
}